const Calendar = () => import("@/views/calendar/Calendar.vue");


const calendar_routes = [
    {
        path: '',
        name: 'Calendar',
        component: Calendar,
        props: route => ({params: route.query, title: 'Calendar'})
    }
]
export default calendar_routes;